import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Chart, registerables } from "chart.js";
import moment from "moment";
import { SharedService } from "../shared/shared.service";
import { DashboardService } from "./dashboard.service";
import { HttpErrorResponse, HttpParams } from "@angular/common/http";
import { CredentialsService } from "../core/credentials.service";
import { AuthenticationService } from "../core/authentication.service";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

import * as html2pdf from "html2pdf.js";

import { reportDashboardTemplate } from "./templates/report-dashboard";
import { MatTabChangeEvent } from "@angular/material/tabs";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { OrganizationService } from "../home/organization/organization.service";

import { toSvg } from "html-to-image";

import { interval, Subject } from "rxjs";
export interface pdfBody {
  body?: string;
  page?: number;
  dateRange: string;
  companyName: string;
  author: string;
  topMotivosConsulta?: string;
  distribucionGenero?: string;
  distribucionEdad?: string;
  tasaRecurrencia?: string;
  usuariosUsoServicio?: string;
  tasaRegistroAcumulada?: string;
  valoracionMedia?: string;
  tasaUso?: string;
  consultasAtendidas?: string;
  usuariosRegistrados?: string;
  firstBlock?: string;
  secondBlock?: string;
  footer?: { firstLine: string; secondLine: string };
}

export interface dataDashboard {
  ageConsultation: {};
  countConsultation: number;
  countFollowUp: number;
  dates: { date: string; quantity: number }[];
  genderConsultation: {};
  rateConsultation: number;
  rateRating: number;
  rateServiceLevel: number;
  recurrenceRate: number;
  topChiefComplaint: [];
  trendConsultation: number | null;
  trendRating: number | null;
  trendServiceLevel: number | null;
  usersWithServiceUsage: number;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  @ViewChild("canvasUser", { static: false }) canvasUser: ElementRef;
  @ViewChild("canvasUserPDF", { static: false }) canvasUserPDF: ElementRef;

  @ViewChild("canvasConsult", { static: false }) canvasConsult: ElementRef;
  @ViewChild("canvasConsultPDF", { static: false })
  canvasConsultPDF: ElementRef;

  @ViewChild("canvasPies", { static: false }) canvasPie: ElementRef;
  @ViewChild("canvasPieGender", { static: false }) canvasPieGender: ElementRef;
  @ViewChild("canvasBarPerAge", { static: false }) canvasBarPerAge: ElementRef;

  @ViewChild("usuariosRegistradosPDF", { static: false })
  usuariosRegistradosPDF: ElementRef;
  @ViewChild("consultasAtendidasPDF", { static: false })
  consultasAtendidasPDF: ElementRef;
  @ViewChild("canvasPiePDF", { static: false }) canvasPiePDF: ElementRef;
  @ViewChild("canvasPieGenderPDF", { static: false })
  canvasPieGenderPDF: ElementRef;
  @ViewChild("canvasBarPerAgePDF", { static: false })
  canvasBarPerAgePDF: ElementRef;

  @ViewChild("savegraph", { static: false }) savegraph: ElementRef;

  chart: any;
  chartPie: any;
  chartPieGender: any;

  consultasAtendidas: any;

  isData = false;
  isLoading = true;
  isLoadingReport = false;

  isRealData = false;

  members: any;
  dashboardData: dataDashboard;
  gendersLength = 0;
  ageConsultationLength = 0;

  totalConsults = 0;
  totalUsers = 0;
  primaries = 0;
  successive = 0;
  totalMembers = 0;
  totalRegistered = 0;

  activeTabIndex = {
    previous: 0,
    current: 0,
  };

  chartTabs = {
    motivosConsulta: {
      title: "Top 10 motivos de consulta",
      position: 0,
      canvaId: "canvasPies",
    },
    consultasPorGenero: {
      title: "Distribución de consultas por género",
      position: 1,
      canvaId: "canvasPieGender",
    },
    consultasPorEdad: {
      title: "Distribución de consultas por edad",
      position: 2,
      canvaId: "canvasBarPerAge",
    },
    pointers: [
      {
        title: "Top 10 motivos de consulta",
        position: 0,
        canvaId: "canvasPies",
      },
      {
        title: "Distribución de consultas por género",
        position: 1,
        canvaId: "canvasPieGender",
      },
      {
        title: "Distribución de consultas por edad",
        position: 2,
        canvaId: "canvasBarPerAge",
      },
    ],
  };

  chartConsultasAtendidas: any;
  chartMotivosConsulta: any;
  chartDistribucionGenero: any;
  chartDistribucionEdad: any;

  dateRanges = [
    {
      label: "Últimos 7 días",
      value: "7",
      time: "days",
    },
    {
      label: " Últimos 30 días",
      value: "30",
      time: "days",
    },
    {
      label: "Últimos 3 meses",
      value: "3",
      time: "month",
    },
    {
      label: "Últimos 6 meses",
      value: "6",
      time: "month",
    },
    {
      label: "Últimos 12 meses",
      value: "12",
      time: "month",
    },
    {
      label: "Desde siempre",
      value: "",
      time: "all",
    },
  ];

  momentDateRange: string = "";

  provider: string;
  providerSelected: any = {};
  providerSelectedHasChanged = false;
  providersList: any[] = [];

  intervalMessageInfo = interval(1000);
  loadingPDF$ = new Subject<void>();

  rangeSelected = {
    label: " Últimos 30 días",
    value: "30",
    time: "days",
  };

  changeRangeSelected = false;

  constructor(
    private readonly credService: CredentialsService,
    private readonly sharedService: SharedService,
    private readonly dashboardService: DashboardService,
    private readonly authenticationService: AuthenticationService,
    private readonly organizationService: OrganizationService
  ) {
    Chart.register(...registerables, ChartDataLabels);
    this.provider = this.credService.credentials.userDetails.provider._id;
  }

  ngOnInit() {
    moment.locale("es");
    this.calculateMomentDateRange();
    this.getProviders();
    this.getData();
  }

  compareAgeRanges(a, b) {
    const specialCase = "menores de 18 años";

    const aValue = String(...Object.keys(a)).toLowerCase();
    const bValue = String(...Object.keys(b)).toLowerCase();

    if (aValue === specialCase) return -1;
    if (bValue === specialCase) return 1;

    const aMatch = aValue.match(/\d+/);
    const bMatch = bValue.match(/\d+/);

    const aNumber = aMatch ? parseInt(aMatch[0]) : 0;
    const bNumber = bMatch ? parseInt(bMatch[0]) : 0;

    return aNumber - bNumber;
  }

  getData() {
    this.isLoading = true;

    const params = this.setParams(this.rangeSelected).set(
      "provider",
      this.provider
    );

    this.dashboardService.getMembersDashboardData(params).subscribe(
      (res: any) => {
        // console.log("DATA Members", res["data"]);
        this.members = res["data"];
        this.totalRegistered = this.sharedService.formatNumber(
          this.members.totalRegisteredPeriod
        );
        this.totalMembers = this.sharedService.formatNumber(
          this.members.totalMembers
        );

        this.dashboardService.getDashboardData(params).subscribe(
          (res: any) => {
            // console.log("DATA Dashboard", res["data"]);
            this.dashboardData = res["data"];
            this.totalConsults = this.sharedService.formatNumber(
              this.dashboardData.countConsultation +
                this.dashboardData.countFollowUp
            );
            this.primaries = this.sharedService.formatNumber(
              this.dashboardData.countConsultation
            );
            this.successive = this.sharedService.formatNumber(
              this.dashboardData.countFollowUp
            );
            if (
              this.members.dates.length <= 0 &&
              this.dashboardData.dates.length <= 0
            ) {
              this.isLoading = false;
            } else {
              if (this.members.dates.length > 0) {
                this.members.dates = this.setStartAndEnd(
                  this.members.dates,
                  this.rangeSelected
                );
              }
              if (this.dashboardData.dates.length > 0) {
                this.dashboardData.dates = this.setStartAndEnd(
                  this.dashboardData.dates,
                  this.rangeSelected
                );
              }
              if (this.rangeSelected.value == "") {
                this.members.trend = null;
                this.dashboardData.trendRating = null;
                this.dashboardData.trendConsultation = null;
                this.dashboardData.trendServiceLevel = null;
              }

              this.isRealData = true;
              this.setData();
            }
          },
          (err: HttpErrorResponse) => {
            this.isLoading = false;
            this.isData = false;
            console.log(err);
          }
        );
      },
      (err: HttpErrorResponse) => {
        console.log(err);
        this.isLoading = false;
        this.isData = false;
      }
    );
  }

  getProviders() {
    this.providersList = [];

    this.authenticationService.getAdminProviders().subscribe(
      (res) => {
        const { data } = res;
        this.provider = data.provider._id;

        if (data?.subsidiary?.length) {
          this.providersList = [data.provider, ...data.subsidiary];
        }

        this.providerSelected = this.providersList.find(
          (item) => this.provider == item._id
        );
        // console.log(this.providerSelected);
      },
      (err: HttpErrorResponse) => {}
    );
  }

  setParams({ value, time }): HttpParams {
    const timeDict = {
      days_7: "7",
      days_30: "30",
      months_3: "3",
      months_6: "6",
      months_12: "12",
    };

    switch (value) {
      case timeDict.days_7: {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "1")
          .set("provider", this.provider);
      }

      case timeDict.days_30: {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "1")
          .set("provider", this.provider);
      }

      case timeDict.months_3: {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "1")
          .set("provider", this.provider);
      }

      case timeDict.months_6: {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "2")
          .set("provider", this.provider);
      }

      case timeDict.months_12: {
        return new HttpParams()
          .set(
            "startDate",
            moment
              .utc()
              .subtract(value, time)
              .startOf("day")
              .format("YYYY-MM-DD")
          )
          .set("endDate", moment.utc().endOf("day").format("YYYY-MM-DD"))
          .set("timeScale", "2")
          .set("provider", this.provider);
      }
      case "": {
        return new HttpParams()
          .set("timeScale", "2")
          .set("provider", this.provider);
      }

      default: {
        return new HttpParams()
          .set(
            "startDate",
            moment.utc().subtract(30, "days").startOf("day").toISOString()
          )
          .set("endDate", moment.utc().endOf("day").toISOString())
          .set("timeScale", "1")
          .set("provider", this.provider);
      }
    }
  }

  capitalize(str: string) {
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
  }

  isNewMonthLabel(label: any[], date, indexLabel: number) {
    let isNewMonth = false;
    label.forEach((label, index) => {
      if (indexLabel == 0) {
        isNewMonth = true;
      }
      if (index < indexLabel) {
        const dateOne = moment.utc(new Date(label).toISOString()).get("month");
        const dateTwo = moment.utc(date).get("month");
        if (dateOne == dateTwo) {
          isNewMonth = false;
        } else {
          isNewMonth = true;
        }
      }
    });
    return isNewMonth;
  }

  setStartAndEnd(dates: any[], { value, time }) {
    const valueTime = time == "days" ? parseInt(value) - 1 : value;

    const startDate = moment.utc().subtract(valueTime, time);
    const endDate = moment.utc();

    if (
      !dates.find((date) => {
        const currentDate = new Date(date.date).toISOString();
        return moment
          .utc(currentDate)
          .startOf(value == "6" || value == "12" ? time : "day")
          .isSame(
            startDate.startOf(value == "6" || value == "12" ? time : "day")
          );
      })
    ) {
      dates.push({ date: startDate.toISOString(), quantity: 0 });
    }
    if (
      !dates.find((date) => {
        const currentDate = new Date(date.date).toISOString();
        return moment
          .utc(currentDate)
          .endOf(value == "6" || value == "12" ? time : "day")
          .isSame(endDate.endOf(value == "6" || value == "12" ? time : "day"));
      })
    ) {
      dates.push({ date: endDate.toISOString(), quantity: 0 });
    }

    dates.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    return dates;
  }

  validateSplit(dates, { value, time }) {
    let status;
    if (value == "30" && dates.length > 12) {
      status = true;
    } else if (value == "3" && dates.length < 14) {
      status = false;
    } else if (value == "3" && dates.length > 14) {
      status = true;
    } else {
      status = false;
    }
    return status;
  }

  splitDates(dates, { value, time }, type) {
    const newDatesArray = [];

    const startDate = moment.utc().subtract(parseInt(value) - 1, time);

    let days = 0;
    let jump = 0;

    if (value == "30" && dates.length >= 12) {
      days = 6;
      jump = 6;
    }
    if (value == "3" && dates.length > 30) {
      days = 14;
      jump = 14;
    } else if (value == "3" && dates.length < 30) {
      days = 7;
      jump = 7;
    }
    let currentDay = 0;

    while (days <= dates.length) {
      let count = 0;
      let starSplit: moment.Moment;
      let endSplit: moment.Moment;
      dates.forEach((date) => {
        const newDate = new Date(date.date).toISOString();
        starSplit = moment.utc(startDate).add(currentDay, "d").startOf("day");
        endSplit = moment
          .utc(startDate)
          .add(days - 1, "d")
          .endOf("day");
        if (
          moment.utc(newDate).isBetween(starSplit, endSplit) ||
          moment.utc(newDate).isSame(starSplit) ||
          moment.utc(newDate).isSame(endSplit)
        ) {
          count = count + date.quantity;
        }
      });

      newDatesArray.push({
        date:
          (newDatesArray.length == 0
            ? this.capitalize(starSplit.format("MMM DD"))
            : starSplit.format("DD")) +
          "-" +
          (endSplit.get("month") != starSplit.get("month")
            ? this.capitalize(endSplit.format("MMM DD"))
            : endSplit.format("DD")),
        quantity: count,
      });

      currentDay = days;

      days = days + jump;
    }
    const daysLeft = dates.filter((dates) => {
      const startDateLeft = moment
        .utc(startDate)
        .add(days - jump, "d")
        .startOf("d");
      const endDate = moment.utc().endOf("d");
      const date = new Date(dates.date).toISOString();

      return (
        moment.utc(date).isBetween(startDateLeft, endDate) ||
        moment.utc(date).isSame(startDateLeft) ||
        moment.utc(date).isSame(endDate)
      );
    });
    if (daysLeft.length > 0) {
      daysLeft.forEach((date) => {
        const isNewMonth = this.isNewMonthLabel(
          dates.map((date) => date.date),
          date.date,
          dates.findIndex((dates) => dates.date == date.date)
        );
        newDatesArray.push({
          date: isNewMonth
            ? this.capitalize(moment.utc(date.date).format("MMM DD"))
            : moment.utc(date.date).format("DD"),
          quantity: date.quantity,
        });
      });
    }

    if (type == "dates") {
      return newDatesArray.map((newArray) => newArray.date);
    }
    if (type == "count") {
      return newDatesArray.map((newArray) => newArray.quantity);
    }
  }

  getGraphicLineLabels(label, index) {
    const indexLabel = label.find((label, indexL) => indexL == index);
    const date = new Date(indexLabel).toISOString();
    const isNewMonth = this.isNewMonthLabel(label, date, index);
    if (
      this.rangeSelected.value == "7" ||
      this.rangeSelected.value == "30" ||
      this.rangeSelected.value == "3"
    ) {
      if (
        moment
          .utc(date)
          .startOf("day")
          .isSame(moment.utc(date).startOf("month")) ||
        isNewMonth
      ) {
        return this.capitalize(moment.utc(date).format("MMM DD"));
      } else {
        return moment.utc(date).format("DD");
      }
    } else {
      return this.capitalize(moment.utc(date).format("MMM"));
    }
  }

  getTooltipsLabel(label) {
    const date = new Date(label).toISOString();
    if (
      this.rangeSelected.value == "7" ||
      this.rangeSelected.value == "30" ||
      this.rangeSelected.value == "3"
    ) {
      return moment.utc(date).format("DD MMMM");
    } else {
      return moment.utc(date).format("MMMM");
    }
  }

  updateChart(chart, newLabels, newData) {
    if (chart) {
      chart.data.labels = newLabels;
      chart.data.datasets.forEach((dataset) => {
        dataset.data = newData;
      });
      chart.update();
    }
  }

  setData() {
    if (!this.isRealData) {
      return;
    }

    const labels = this.validateSplit(
      this.dashboardData.dates,
      this.rangeSelected
    )
      ? this.splitDates(this.dashboardData.dates, this.rangeSelected, "dates")
      : this.dashboardData.dates.map((dates) => dates.date);
    const data = this.validateSplit(
      this.dashboardData.dates,
      this.rangeSelected
    )
      ? this.splitDates(this.dashboardData.dates, this.rangeSelected, "count")
      : this.dashboardData.dates.map((dates) => dates.quantity);
    const labelsUsers = this.validateSplit(
      this.members.dates,
      this.rangeSelected
    )
      ? this.splitDates(this.members.dates, this.rangeSelected, "dates")
      : this.members.dates.map((dates) => dates.date);
    const dataUsers = this.validateSplit(this.members.dates, this.rangeSelected)
      ? this.splitDates(this.members.dates, this.rangeSelected, "count")
      : this.members.dates.map((dates) => dates.quantity);

    const { labelsPie, labelsPiePDF, dataPie } =
      this.dashboardData.topChiefComplaint.reduce(
        (acc: any, ite: any) => {
          acc.labelsPiePDF.push(`${ite.name} - ${ite.count}`);
          acc.labelsPie.push(`${ite.name}`);
          acc.dataPie.push(ite.count);
          return acc;
        },
        { labelsPie: [], labelsPiePDF: [], dataPie: [] }
      );

    const labelsPieGender = [];
    const labelsPieGenderPDF = [];
    const dataPieGender = [];

    for (let [key, value] of Object.entries(
      this.dashboardData.genderConsultation
    )) {
      //tiene espaciado para que se asemeje al grafico de arriba y de la ilusion de que está alineado
      labelsPieGenderPDF.push(`${key} - ${value}`);
      labelsPieGender.push(`${key}`);
      dataPieGender.push(value);
    }

    let labelsPiePerAgePDF: string[] = [];
    let labelsPiePerAge: string[] = [];
    let dataPiePerAge: number[] = [];

    if (Object.values(this.dashboardData.ageConsultation).length) {
      const arrayAge = [];

      for (let [key, value] of Object.entries(
        this.dashboardData.ageConsultation
      )) {
        let body = { [key]: value };
        arrayAge.push(body);
      }

      const ageConsultation = arrayAge.sort(this.compareAgeRanges);

      ({ labelsPiePerAge, labelsPiePerAgePDF, dataPiePerAge } =
        ageConsultation.reduce(
          (acc, item) => {
            let [key] = Object.keys(item);
            const [value] = Object.values(item);

            if (key === "menores de 18 años") {
              key = "Menores de 18 años";
            }

            acc.labelsPiePerAgePDF.push(`${key} - ${value}`);
            acc.labelsPiePerAge.push(
              `${
                key == "Menores de 18 años" || key == "+70 años"
                  ? key
                  : key.replace("a", "-")
              }`
            );
            acc.dataPiePerAge.push(value);
            return acc;
          },
          { labelsPiePerAge: [], labelsPiePerAgePDF: [], dataPiePerAge: [] }
        ));

      // console.log({ labelsPiePerAge, labelsPiePerAgePDF, dataPiePerAge });
    }

    this.gendersLength = dataPieGender.length;
    this.ageConsultationLength = dataPiePerAge.length;

    const numero = 2 as number;

    //canvas2

    const usuariosRegistrados = <HTMLCanvasElement>(
      document.createElement("canvas")
    );
    const usuariosRegistradosPDF = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;
    usuariosRegistrados.height = 115;
    usuariosRegistradosPDF.height = 80;
    usuariosRegistradosPDF.id = "canvasUserPDF";
    usuariosRegistrados.id = "canvasUser";

    const consultasAtendidas = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;
    const consultasAtendidasPDF = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;
    consultasAtendidas.height = 115;
    consultasAtendidasPDF.height = 80;
    consultasAtendidas.id = "canvasConsult";
    consultasAtendidasPDF.id = "canvasConsultPDF";

    const canvasPie = document.createElement("canvas") as HTMLCanvasElement;
    const canvasPiePDF = document.createElement("canvas") as HTMLCanvasElement;
    canvasPiePDF.height = 699;
    canvasPiePDF.width = 699;

    canvasPiePDF.id = "canvasPiePDF";
    canvasPie.id = "canvasPie";

    const canvasPieGender = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;
    const canvasPieGenderPDF = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;
    canvasPieGender.id = "canvasPieGender";
    canvasPieGenderPDF.id = "canvasPieGenderPDF";

    const canvasBarPerAge = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;
    const canvasBarPerAgePDF = document.createElement(
      "canvas"
    ) as HTMLCanvasElement;

    canvasBarPerAge.height = 120;
    canvasBarPerAgePDF.height = 120;
    canvasBarPerAge.id = "canvasBarPerAge";
    canvasBarPerAgePDF.id = "canvasBarPerAgePDF";

    let ctxUsuariosRegistrados = usuariosRegistrados.getContext("2d");
    let ctxUsuariosRegistradosPDF = usuariosRegistradosPDF.getContext("2d");

    let ctxConsultasAtendidas = consultasAtendidas.getContext("2d");
    let ctxConsultasAtendidasPDF = consultasAtendidasPDF.getContext("2d");

    let ctxPie = canvasPie.getContext("2d");
    let ctxPiePDF = canvasPiePDF.getContext("2d");
    var gradient = ctxUsuariosRegistrados.createLinearGradient(
      100,
      0,
      100,
      250
    );
    var gradient2 = ctxConsultasAtendidas.createLinearGradient(
      100,
      0,
      100,
      250
    );

    let ctxPieGender = canvasPieGender.getContext("2d");
    let ctxPieGenderPDF = canvasPieGenderPDF.getContext("2d");

    let ctxPerAge = canvasBarPerAge.getContext("2d");
    let ctxPerAgePDF = canvasBarPerAgePDF.getContext("2d");

    gradient.addColorStop(0, "rgba(99, 145, 244, 1)");
    gradient.addColorStop(1, "rgba(99, 145, 244, 0.1)");
    gradient2.addColorStop(0, "rgba(42, 83, 169, 1)");
    gradient2.addColorStop(1, "rgba(42, 83, 169, 0.1)");

    const backgroundAndHoverColorsForChartsTabs = [
      //rgba(60, 118, 241, 1)
      "rgba(2, 12, 111, 1)",
      "rgba(6, 36, 76, 1)",
      "rgba(10, 60, 127, 1)",
      "rgba(14, 84, 178, 1)",
      "rgba(18, 108, 229, 1)",
      "rgba(60, 118, 241, 1)",
      "rgba(90, 161, 255, 1)",
      "rgba(33, 56, 100, 1)",
      "rgba(57, 78, 117, 1)",
      "rgba(107, 122, 152, 1)",
      "rgba(206, 211, 221, 1)",
      "rgba(205, 37, 45, 1)",
    ];

    const backgroundAndHoverColorsForChartDistribucionPorEdad = [
      "rgba(6, 36, 76, 1)",
      ,
      "rgba(10, 60, 127, 1)",
      "rgba(18, 108, 229, 1)",
      "rgba(90, 161, 255, 1)",
      "rgba(57, 78, 117, 1)",
      "rgba(205, 37, 45, 1)",
    ];

    //Usuarios registrados
    this.chart = new Chart(ctxUsuariosRegistrados, {
      type: "line",
      data: {
        labels: this.validateSplit(this.members.dates, this.rangeSelected)
          ? labelsUsers
          : labelsUsers.map((date) => this.getTooltipsLabel(date)),
        datasets: [
          {
            label: null,
            data: dataUsers,
            borderWidth: 2,
            fill: true,
            borderColor: "rgba(99, 145, 244, 1)",
            backgroundColor: gradient,
            borderCapStyle: "round",
            tension: 0.5,
            pointBorderWidth: 3,
            pointBackgroundColor: "#51A7FD",
            pointHoverRadius: 10,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: "#FFFFFF",
            pointHoverBackgroundColor: "#51A7FD",
          },
        ],
      },
      options: {
        maintainAspectRatio: true,
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              padding: 0,
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              maxTicksLimit: 7,
              callback: (value, index, ticks) => {
                return this.validateSplit(
                  this.members.dates,
                  this.rangeSelected
                )
                  ? labelsUsers.find((label, indexL) => indexL == index)
                  : this.getGraphicLineLabels(labelsUsers, index);
              },
            },
          },
          y: {
            display: false,
            suggestedMax: Math.max(...dataUsers) + Math.max(...dataUsers) / 10,
            suggestedMin: Math.max(...dataUsers) / 10,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "rgba(99, 145, 244, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset.data;
                const total: any = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue: any = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return `${percentage}%`;
              },
            },
          },
          datalabels: {
            display: false,
            align: "end",
            anchor: "start",
            formatter(value) {
              return `${value}`;
            },
            color: "black",
            font: {
              weight: "bold",
              size: 14,
            },
          },
        },
      },
    });

    //Usuarios registrados - PDF
    this.chart = new Chart(ctxUsuariosRegistradosPDF, {
      type: "line",
      data: {
        labels: this.validateSplit(this.members.dates, this.rangeSelected)
          ? labelsUsers
          : labelsUsers.map((date) => this.getTooltipsLabel(date)),
        datasets: [
          {
            label: null,
            data: dataUsers,
            borderWidth: 2,
            fill: true,
            borderColor: "rgba(99, 145, 244, 1)",
            backgroundColor: gradient,
            borderCapStyle: "round",
            tension: 0.5,
            pointBorderWidth: 3,
            pointBackgroundColor: "#51A7FD",
            pointHoverRadius: 10,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: "#FFFFFF",
            pointHoverBackgroundColor: "#51A7FD",
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              padding: 0,
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              maxTicksLimit: 7,
              callback: (value, index, ticks) => {
                return this.validateSplit(
                  this.members.dates,
                  this.rangeSelected
                )
                  ? labelsUsers.find((label, indexL) => indexL == index)
                  : this.getGraphicLineLabels(labelsUsers, index);
              },
            },
          },
          y: {
            display: false,
            suggestedMax: Math.max(...dataUsers) + Math.max(...dataUsers) / 10,
            suggestedMin: Math.max(...dataUsers) / 10,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            backgroundColor: "rgba(99, 145, 244, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset.data;
                const total: any = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue: any = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return `${percentage}%`;
              },
            },
          },
          datalabels: {
            display: true,
            align: "end",
            anchor: "start",
            formatter(value) {
              return `${value}`;
            },
            color: "black",
            font: {
              weight: "bold",
              size: 16,
            },
            padding: 12,
          },
        },
      },
    });

    //Consultas atendidas
    this.chart = new Chart(ctxConsultasAtendidas, {
      type: "line",
      data: {
        labels: this.validateSplit(this.dashboardData.dates, this.rangeSelected)
          ? labels
          : labels.map((date) => this.getTooltipsLabel(date)),
        datasets: [
          {
            label: null,
            data: data,
            borderWidth: 2,
            fill: true,
            borderColor: "rgba(99, 145, 244,1)",
            backgroundColor: gradient2,
            borderCapStyle: "round",
            tension: 0.5,
            pointBorderWidth: 3,
            pointBackgroundColor: "#2751A5",
            pointHoverRadius: 10,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: "#FFFFFF",
            pointHoverBackgroundColor: "#2751A5",
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              padding: 0,
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              maxTicksLimit: 7,
              callback: (_, index, ticks) => {
                return this.validateSplit(
                  this.dashboardData.dates,
                  this.rangeSelected
                )
                  ? labels.find((_, indexL) => indexL == index)
                  : this.getGraphicLineLabels(labels, index);
              },
            },
          },
          y: {
            suggestedMax: Math.max(...data) + Math.max(...data) / 10,
            suggestedMin: Math.max(...data) / 10,
            display: false,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: "index",
            intersect: false,
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset.data;
                const total: any = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue: any = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return `${percentage}%`;
              },
            },
          },
          datalabels: {
            display: false,
            align: "end",
            anchor: "start",
            formatter(value) {
              return `${value}`;
            },
            color: "black",
            font: {
              weight: "bold",
              size: 14,
            },
          },
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
      },
    });

    //Consultas atendidas - PDF
    this.chart = new Chart(ctxConsultasAtendidasPDF, {
      type: "line",
      data: {
        labels: this.validateSplit(this.dashboardData.dates, this.rangeSelected)
          ? labels
          : labels.map((date) => this.getTooltipsLabel(date)),
        datasets: [
          {
            label: null,
            data: data,
            borderWidth: 2,
            fill: true,
            borderColor: "rgba(99, 145, 244,1)",
            backgroundColor: gradient2,
            borderCapStyle: "round",
            tension: 0.5,
            pointBorderWidth: 3,
            pointBackgroundColor: "#2751A5",
            pointHoverRadius: 10,
            pointHoverBorderWidth: 3,
            pointHoverBorderColor: "#FFFFFF",
            pointHoverBackgroundColor: "#2751A5",
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              padding: 0,
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              maxTicksLimit: 7,
              callback: (_, index, ticks) => {
                return this.validateSplit(
                  this.dashboardData.dates,
                  this.rangeSelected
                )
                  ? labels.find((_, indexL) => indexL == index)
                  : this.getGraphicLineLabels(labels, index);
              },
            },
          },
          y: {
            suggestedMax: Math.max(...data) + Math.max(...data) / 10,
            suggestedMin: Math.max(...data) / 10,
            display: false,
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: "index",
            intersect: false,
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset.data;
                const total: any = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue: any = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return `${percentage}%`;
              },
            },
          },
          datalabels: {
            display: true,
            align: "end",
            anchor: "start",
            formatter(value) {
              return `${value}`;
            },
            color: "black",
            font: {
              weight: "bold",
              size: 14,
            },
            padding: 12,
          },
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
      },
    });

    //NAVEGACION

    //TAB #1 MOTIVOS DE CONSULTA
    this.chartMotivosConsulta = new Chart(ctxPie, {
      type: "pie",
      data: {
        labels: labelsPie,
        datasets: [
          {
            label: null,
            data: dataPie,
            borderColor: "rgba(36, 71, 145, 0)",
            backgroundColor: backgroundAndHoverColorsForChartsTabs,
            hoverBackgroundColor: backgroundAndHoverColorsForChartsTabs,
            hoverBorderColor: "rgba(36, 71, 145, 0)",
            hoverOffset: 9,
            spacing: 0,
          },
        ],
      },
      options: {
        radius: 145,
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            mode: "nearest",
            intersect: false,
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 16,
              weight: "bold",
            },

            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset.data;
                const total = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return [tooltipItem.label, `${percentage}%`];
              },
            },
          },
          legend: {
            position: "right",
            fullSize: false,
            labels: {
              padding: 15,
              textAlign: "left",
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              boxWidth: 35,
              boxHeight: 8,
            },
          },
          datalabels: {
            display: true,
            align: "center",
            anchor: "center",
            formatter(value) {
              return `${value}`;
            },
            color: "white",
            font: {
              weight: "bold",
              size: 14,
            },
          },
        },
        animations: {},
      },
    });

    //TAB #1-PDF
    this.chart = new Chart(ctxPiePDF, {
      type: "pie",
      data: {
        labels: labelsPiePDF,
        datasets: [
          {
            label: null,
            data: dataPie,
            borderColor: "rgba(36, 71, 145, 0)",
            backgroundColor: backgroundAndHoverColorsForChartsTabs,
            hoverBackgroundColor: backgroundAndHoverColorsForChartsTabs,
            hoverBorderColor: "rgba(36, 71, 145, 0)",
            hoverOffset: 9,
            spacing: 0,
          },
        ],
      },
      options: {
        radius: 115,
        responsive: false,
        maintainAspectRatio: true,
        aspectRatio: 1,
        plugins: {
          // title:{
          //   display: true,
          //   text: 'Motivos de consulta',
          //   align: 'start',
          //   font: {
          //     family: "'Nunito', sans-serif",
          //     size: 20,
          //     weight: 'bold',
          //   },
          //   padding: 20,
          // },

          tooltip: {
            enabled: true,
            mode: "nearest",
            intersect: false,
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },

            callbacks: {
              label: function (tooltipItem) {
                // console.log(tooltipItem);
                const dataset = tooltipItem.dataset.data;
                const total = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return `${currentValue}`;
              },
            },
            external: function (context) {
              const tooltipModel = context.tooltip;
              // console.log(context);
              // console.log(tooltipModel);

              if (tooltipModel.opacity === 0) {
                return;
              }
            },
          },
          legend: {
            display: true,
            position: "right",
            fullSize: false,
            labels: {
              padding: 5,
              textAlign: "left",
              font: {
                family: "'Nunito', sans-serif",
                size: 13,
              },
              boxWidth: 35,
              boxHeight: 8,
            },
          },
          datalabels: {
            display: true,
            align: "end",
            anchor: "end",
            clamp: false,
            // rotation: 15,
            // clamp: false,
            // clip: false,
            // backgroundColor: "rgba(42, 83, 169, 1)",
            // padding: {
            //   right: 5,
            //   left: 25,
            //   bottom: 15,
            //   top: 25,
            // },

            formatter(value, context) {
              const dataset = context.chart.data.datasets[0].data;
              const total = dataset.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) as number;
              const percentage = ((value / total) * 100).toFixed(2);
              return `${percentage}%`;
            },
            color: "black",
            font: {
              family: "'Nunito', sans-serif",
              size: 10,
              weight: "bold",
              style: "normal",
              lineHeight: 2.2,
            },
          },
        },
        animations: {},
      },
    });

    //TAB #2 DISTRIBUCIÓN DE CONSULTAS POR GÉNERO
    this.chartDistribucionGenero = new Chart(ctxPieGender, {
      type: "pie",
      data: {
        labels: labelsPieGender,
        datasets: [
          {
            label: null,
            data: dataPieGender,
            borderColor: "rgba(36, 71, 145, 0)",
            backgroundColor: backgroundAndHoverColorsForChartsTabs,
            hoverBackgroundColor: backgroundAndHoverColorsForChartsTabs,
            hoverBorderColor: backgroundAndHoverColorsForChartsTabs,
            hoverOffset: 9,
          },
        ],
      },
      options: {
        radius: 145,
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            mode: "index",
            intersect: true,
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 16,
            },
            callbacks: {
              label: function (tooltipItem) {
                // console.log(tooltipItem);
                const dataset = tooltipItem.dataset.data;
                const total = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return [tooltipItem.label, `${percentage}%`];
              },
            },
          },
          legend: {
            position: "right",
            fullSize: false,
            labels: {
              padding: 15,
              textAlign: "left",
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              boxWidth: 35,
              boxHeight: 7,
            },
          },
          datalabels: {
            display: true,
            align: "center",
            anchor: "center",
            formatter(value, context) {
              const dataset = context.chart.data.datasets[0].data;
              const total = dataset.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) as number;
              const percentage = ((value / total) * 100).toFixed(2);
              return `${value}`;
            },
            color: "white",
            font: {
              weight: "bold",
              size: 14,
            },
          },
        },
        animations: {},
      },
    });

    //TAB #2-PDF
    this.chart = new Chart(ctxPieGenderPDF, {
      type: "pie",
      data: {
        labels: labelsPieGenderPDF,
        datasets: [
          {
            label: null,
            data: dataPieGender,
            borderColor: "rgba(36, 71, 145, 0)",
            backgroundColor: backgroundAndHoverColorsForChartsTabs,
            hoverBackgroundColor: backgroundAndHoverColorsForChartsTabs,
            hoverBorderColor: backgroundAndHoverColorsForChartsTabs,
            hoverOffset: 9,
            spacing: 0,
          },
        ],
      },
      options: {
        radius: 115,
        responsive: true,
        plugins: {
          tooltip: {
            enabled: true,
            mode: "index",
            intersect: false,
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
            callbacks: {
              //calculate de percentage of the data and show it in the tooltip
              label: function (tooltipItem) {
                // console.log(tooltipItem);
                const dataset = tooltipItem.dataset.data;
                const total = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return `${percentage}%`;
              },
            },
          },
          legend: {
            display: true,
            position: "right",
            fullSize: false,
            labels: {
              padding: 5,
              textAlign: "left",
              font: {
                family: "'Nunito', sans-serif",
                size: 13,
              },
              boxWidth: 35,
              boxHeight: 8,
            },
          },
          datalabels: {
            display: true,
            align: "end",
            anchor: "end",
            formatter(value, context) {
              const dataset = context.chart.data.datasets[0].data;
              const total = dataset.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) as number;
              const percentage = ((value / total) * 100).toFixed(2);
              return `${percentage}%`;
            },
            color: "black",
            font: {
              weight: "bold",
              size: 14,
            },
            padding: {
              right: 20,
              left: 30,
              top: 40,
            },
          },
        },
        animations: {},
      },
    });

    //TAB #3 DISTRIBUCIÓN DE CONSULTAS POR EDAD
    const chartPerAge = new Chart(ctxPerAge, {
      type: "bar",
      data: {
        labels: labelsPiePerAge,
        datasets: [
          {
            label: null,
            data: dataPiePerAge,
            backgroundColor:
              backgroundAndHoverColorsForChartDistribucionPorEdad,
            hoverBackgroundColor:
              backgroundAndHoverColorsForChartDistribucionPorEdad,
            borderWidth: 0,
            barThickness: 80,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
              borderColor: "rgba(0, 0, 0, 1)",
            },
            ticks: {
              padding: 0,
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
              },
              color: "black",
              callback: function (index: number) {
                const label = labelsPiePerAge[index];

                if (!label) return "-";

                const firstLetter = label[0].toUpperCase();

                if (label == "Menores de 18 años") {
                  return [
                    `${firstLetter}${label.substring(1, 10)}`,
                    `${label.substring(10)}`,
                  ];
                } else if (firstLetter == "+") {
                  return label;
                } else {
                  const newLabel = label.split(" años")[0];
                  return [`${newLabel}`, "años"];
                }
              },
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
            suggestedMax:
              Math.max(...dataPiePerAge) + Math.max(...dataPiePerAge) / 10,
          },
        },
        plugins: {
          legend: {
            display: false,
            labels: {
              font: {
                family: "'Nunito', sans-serif",
                size: 16,
                weight: "bold",
              },
            },
          },
          datalabels: {
            display: true,
            align: "end",
            anchor: "end",
            clamp: true,
            formatter(value, context) {
              const dataset = context.chart.data.datasets[0].data;
              const total = dataset.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) as number;
              return `${value}`;
            },
            color: "black",
            font: {
              family: "'Nunito', sans-serif",
              size: 16,
              weight: "bold",
            },
          },
          tooltip: {
            enabled: true,
            mode: "index",
            intersect: false,
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset.data;
                const total: any = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue: any = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(1);
                return `${percentage}%`;
              },
            },
          },
        },
        animation: {
          onComplete: function () {
            // console.log("onComplete");
            // console.log(chartPerAge.toBase64Image());
          },
        },
      },
    });

    //TAB #3-PDF
    const chartPerAgePDF = new Chart(ctxPerAgePDF, {
      type: "bar",
      data: {
        labels: labelsPiePerAgePDF,
        datasets: [
          {
            label: null,
            data: dataPiePerAge,
            backgroundColor:
              backgroundAndHoverColorsForChartDistribucionPorEdad,
            hoverBackgroundColor:
              backgroundAndHoverColorsForChartDistribucionPorEdad,
            borderWidth: 0,
            barThickness: 80,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
              borderColor: "rgba(0, 0, 0, 1)",
            },
            ticks: {
              padding: 0,
              font: {
                family: "'Nunito', sans-serif",
                size: 14,
                // weight: 'bold',
              },
              color: "black",
              callback: function (index: number) {
                const label = labelsPiePerAge[index];
                const value = dataPiePerAge[index];

                if (!label) return "-";

                const firstLetter = label[0].toUpperCase();

                if (label.toLocaleLowerCase() == "menores de 18 años") {
                  return [
                    `${firstLetter}${label.substring(1, 10)}`,
                    `${label.substring(10)} - ${value}`,
                  ];
                } else if (firstLetter == "+") {
                  return label + ` - ${value}`;
                } else {
                  const newLabel = label.split(" años")[0];
                  return [`${newLabel}`, `años - ${value}`];
                }
              },
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
            suggestedMax:
              Math.max(...dataPiePerAge) + Math.max(...dataPiePerAge) / 8,
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            align: "end",
            anchor: "end",
            clamp: true,
            formatter(value, context) {
              const dataset = context.chart.data.datasets[0].data;
              const total = dataset.reduce(
                (sum: number, val: number) => sum + val,
                0
              ) as number;
              const percentage = ((value / total) * 100).toFixed(1);
              return `${percentage}%`;
            },
            color: "black",
            font: {
              weight: "bold",
              size: 14,
            },
          },
          tooltip: {
            enabled: true,
            mode: "index",
            intersect: false,
            backgroundColor: "rgba(42, 83, 169, 1)",
            displayColors: false,
            bodyColor: "#FFFFFF",
            padding: 10,
            titleAlign: "center",
            bodyAlign: "center",
            footerAlign: "center",
            titleFont: {
              family: "'Nunito', sans-serif",
              size: 14,
            },
            callbacks: {
              label: function (tooltipItem) {
                const dataset = tooltipItem.dataset.data;
                const total: any = dataset.reduce(
                  (sum: number, value: number) => sum + value,
                  0
                );
                const currentValue: any = dataset[tooltipItem.dataIndex];
                const percentage = ((currentValue / total) * 100).toFixed(2);
                return `${percentage}%`;
              },
            },
          },
        },
        // animation: false
        animation: {
          onComplete(event) {
            // console.log("onComplete");
            document
              .querySelector("#savegraph")
              .setAttribute("href", this.toBase64Image());
          },
          // onComplete: function (animation) {

          //   console.log('onComplete');

          //   //().nativeElement.href = chartPerAgePDF.toBase64Image();
          //   document.querySelector("#savegraph").setAttribute("href", this.toBase64Image());

          //   // console.log('onComplete');

          //   // console.log(chartPerAgePDF.toBase64Image());
          // }
        },
      },
    });

    // console.log(chartPerAgePDF.toBase64Image());

    //DIBUJO LO QUE ESTÁ EN EL DOM
    switch (this.activeTabIndex.current) {
      case 0: {
        document
          .querySelector(`#${this.chartTabs.motivosConsulta.canvaId}`)
          .appendChild(canvasPie);

        break;
      }
      case 1: {
        document
          .querySelector(`#${this.chartTabs.consultasPorGenero.canvaId}`)
          .appendChild(canvasPieGender);

        break;
      }
      case 2: {
        document
          .querySelector(`#${this.chartTabs.consultasPorEdad.canvaId}`)
          .appendChild(canvasBarPerAge);

        break;
      }
    }
    //PDF consultasAtendidasPDF
    document
      .querySelector("#usuariosRegistradosPDF")
      .appendChild(usuariosRegistradosPDF); //usuariosRegistradosPDF
    document
      .querySelector("#consultasAtendidasPDF")
      .appendChild(consultasAtendidasPDF);
    document.querySelector("#canvasPiePDF").appendChild(canvasPiePDF);
    document
      .querySelector("#canvasPieGenderPDF")
      .appendChild(canvasPieGenderPDF);
    document
      .querySelector("#canvasBarPerAgePDF")
      .appendChild(canvasBarPerAgePDF);
    //FIN PDF

    document.querySelector("#canvas2").appendChild(usuariosRegistrados);
    document.querySelector("#canvas3").appendChild(consultasAtendidas);

    this.isLoading = false;
    this.isData = true;
  }

  changeTab(activeTab: MatTabChangeEvent) {
    const {
      index,
      tab: { isActive },
    } = activeTab;

    if (!isActive) return;

    this.activeTabIndex["previous"] = this.activeTabIndex.current;
    this.activeTabIndex["current"] = index;

    // console.log(this.activeTabIndex);

    if (!this.isRealData) return;
    this.removeCanvas();
    this.setData();
  }

  isInteger(number: number) {
    if (number - Math.floor(number) == 0) {
      return number;
    } else {
      return number.toFixed(1);
    }
  }

  thousandSeparator(number: number) {
    if (!isNaN(number)) {
      return Number(number).toLocaleString("es-ES");
    }
  }

  calculateMomentDateRange() {
    const formatDate = "DD/MM/YYYY";
    const formatTime = "hh:mm a";
    let string = "";

    switch (this.rangeSelected.time) {
      case "days":
        {
          const date = moment
            .utc()
            .subtract(this.rangeSelected.value, "days")
            .format(`${formatDate}`);
          string = `${date} al ${moment
            .utc()
            .format(`${formatDate}`)} ${moment().format(`${formatTime}`)}`;
        }
        break;
      case "month":
        {
          const date = moment
            .utc()
            .subtract(this.rangeSelected.value, "months")
            .format(`${formatDate}`);
          string = `${date} al ${moment
            .utc()
            .format(`${formatDate}`)} ${moment().format(`${formatTime}`)}`;
        }
        break;
      case "all":
        {
          string = "Desde siempre";
        }
        break;
    }
    if (string) {
      string = string.replace(/(am|pm)/, (match) => match.split("").join(".")); //reemplaza am y pm por a.m. y p.m.
    }

    this.momentDateRange = string;
  }

  changeRange(range: string) {
    this.changeRangeSelected = true;
    this.isData = false;
    this.isLoading = true;
    this.rangeSelected = this.dateRanges.find(
      (dateRange) => dateRange.value == range
    );

    // console.log(this.rangeSelected);

    this.calculateMomentDateRange();

    this.removeCanvas();

    this.getData();
  }

  changeProvider(provider) {
    this.providerSelectedHasChanged = true;
    this.providerSelected = provider;
    this.provider = provider._id;

    this.removeCanvas();
    this.getData();
  }

  removePreviousOrCurrentCanvaElement() {
    // console.log(this.activeTabIndex);

    //si no hay data no hay nada que remover
    if (!this.isRealData) return;

    const { canvaId: canvaIdCurrent } = this.chartTabs["pointers"].find(
      (pointer) => pointer["position"] === this.activeTabIndex["current"]
    );

    const { canvaId: canvaIdPrevious } = this.chartTabs["pointers"].find(
      (pointer) => pointer["position"] === this.activeTabIndex["previous"]
    );

    switch (canvaIdCurrent) {
      case "canvasPies": {
        if (this.canvasPie?.nativeElement) {
          const container = this.canvasPie.nativeElement as HTMLElement;
          const canvas = container.querySelector("canvas") as HTMLCanvasElement;
          if (canvas) container.removeChild(canvas);
        }
        break;
      }
      case "canvasPieGender": {
        if (this.canvasPieGender?.nativeElement) {
          const container = this.canvasPieGender.nativeElement as HTMLElement;
          const canvas = container.querySelector("canvas") as HTMLCanvasElement;
          if (canvas) container.removeChild(canvas);
        }
        break;
      }
      case "canvasBarPerAge": {
        if (this.canvasBarPerAge?.nativeElement) {
          const container = this.canvasBarPerAge.nativeElement as HTMLElement;
          const canvas = container.querySelector("canvas") as HTMLCanvasElement;
          if (canvas) container.removeChild(canvas);
        }
        break;
      }
    }
  }

  removeCanvas() {
    if (this.canvasUser?.nativeElement) {
      const container = this.canvasUser.nativeElement as HTMLElement;
      const canvas = container.querySelector("canvas") as HTMLCanvasElement;
      if (canvas) container.removeChild(canvas);
    }

    if (this.canvasConsult?.nativeElement) {
      const container = this.canvasConsult.nativeElement as HTMLElement;
      const canvas = container.querySelector("canvas") as HTMLCanvasElement;
      if (canvas) container.removeChild(canvas);
    }

    /** PDF */

    if (this.consultasAtendidasPDF.nativeElement) {
      const container = this.consultasAtendidasPDF.nativeElement as HTMLElement;
      const canvas = container.querySelector("canvas") as HTMLCanvasElement;
      if (canvas) container.removeChild(canvas);
    }

    if (this.usuariosRegistradosPDF.nativeElement) {
      const container = this.usuariosRegistradosPDF
        .nativeElement as HTMLElement;
      const canvas = container.querySelector("canvas") as HTMLCanvasElement;
      if (canvas) container.removeChild(canvas);
    }

    if (this.canvasPiePDF.nativeElement) {
      const container = this.canvasPiePDF.nativeElement as HTMLElement;
      const canvas = container.querySelector("canvas") as HTMLCanvasElement;
      if (canvas) container.removeChild(canvas);
    }

    if (this.canvasPieGenderPDF.nativeElement) {
      const container = this.canvasPieGenderPDF.nativeElement as HTMLElement;
      const canvas = container.querySelector("canvas") as HTMLCanvasElement;
      if (canvas) container.removeChild(canvas);
    }

    if (this.canvasBarPerAgePDF.nativeElement) {
      const container = this.canvasBarPerAgePDF.nativeElement as HTMLElement;
      const canvas = container.querySelector("canvas") as HTMLCanvasElement;
      if (canvas) container.removeChild(canvas);
    }

    this.removePreviousOrCurrentCanvaElement();
  }

  saveAsPdf() {
    this.isLoadingReport = true;
    this.organizationService.showMessage("Iniciando descarga...", "success");
    document.body.style.overflow = "hidden";

    // this.intervalMessageInfo.pipe(takeUntil(this.loadingPDF$)).subscribe(() => {
    // this.organizationService.showMessage('Generando reporte...', 'success');
    // });

    let { firstName, lastName } = this.credService.credentials.userDetails;

    let businessName = "";
    if (this.providerSelected) {
      businessName = this.providerSelected.businessName;
    } else {
      businessName =
        this?.credService?.credentials?.userDetails?.provider?.businessName;
    }

    // const { businessName } = Object.values(this.providerSelected)?.length
    // ? this.providerSelected
    // : this.credService.credentials.userDetails.provider;

    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

    const body: pdfBody = {
      dateRange: this.momentDateRange,
      companyName: businessName,
      author: `${firstName} ${lastName}`,
      footer: {
        firstLine: `Calle Altagracia, Edf. P&G, Torre Sur, Piso 01, OF S/N. Urb. Sorokaima, Sector La Trinidad, Caracas / Miranda.`,
        secondLine: `Zona postal 1090.`,
      },
    };

    /**Context:
     * Este array contiene todos los gráficos
     * Para agregar un gráfico nuevo, se debe agregar la clase chart-container al contenedor del gráfico
     * seguido del data-chartname con el nombre del gráfico
     * tambien agregar a la interfaz pdfBody el nombre del gráfico
     */
    const charts = document.querySelectorAll(
      ".chart-container-pdf"
    ) as NodeListOf<HTMLElement>;

    const chartPromises = Array.from(charts).map((chart) => {
      const chartKey = chart.dataset?.chartname;

      return new Promise<void>((resolve, reject) => {
        requestAnimationFrame(() => {
          toSvg(chart, { quality: 0.8, pixelRatio: 1.5, cacheBust: false })
            .then((dataUrl) => {
              if (chartKey && chartKey !== "undefined") {
                body[chartKey] = dataUrl;
              }
              resolve();
            })
            .catch((error) => {
              console.error("Error generating image:", error);
              reject(error);
            });
        });
      });

      // return toSvg(chart, { quality: 0.92, pixelRatio: 2, cacheBust: false })
      //   .then( function(dataUrl) {
      //     if (chartKey && chartKey !== 'undefined') {
      //       body[chartKey] = dataUrl;
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('Error generating image:', error);
      //   });
    });

    Promise.all(chartPromises).then(() => {
      //primera pagina
      const body_1 = function (body: pdfBody): string {
        return `
          <div class="row justify-content-center flex-column align-items-center" style="gap: 2px">
            
            <img style="width: 16cm;" src="${body["usuariosRegistrados"]}"/>
            <img style="width: 16cm;" src="${body["consultasAtendidas"]}"/>

          </div>

          <div class="footer-1">
            <div class="container-fluid container-footer text-center d-flex justify-content-center flex-column">
              <p>
                <span style="font-family: 'Nunito', sans-serif; font-weight:300; font-size:12px;"> Generado por: ${body.author} </span> <br><br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:300; font-size:12px;"> Si tienes preguntas sobre alguna métrica, no dudes en contactarnos en <a style="color: white;" href="mailto:atencionalcliente@holadoc.com">atencionalcliente@holadoc.com</a> Estamos aquí para ayudarte. </span> <br><br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:600; font-size:12px;"> ${body.footer.firstLine} </span> <br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:600; font-size:12px;" > ${body.footer.secondLine} </span>
              </p>
              <div class="d-flex justify-content-around">
                <p style="font-family: 'Nunito', sans-serif; font-weight:700; font-size:12px;"> Tlf: 0212-2346550 </p>
                <p style="font-family: 'Nunito', sans-serif; font-weight:700; font-size:12px;"> RIF J-40959877-2 </p>
              </div>

            </div>
          </div>

          <style>
            .footer-1 {
              color: #FFFFFF;
              width: auto;
              overflow: hidden;
              border: unset;

              margin-top: -48px;
              z-index: 100;
              background-position: center;
              background-repeat: no-repeat; 
              background-size: cover;
              background-image: url(../../../assets/images/footer_pdf.svg); 
            }

            .container-footer {
              padding: 130px 5px 5px 5px;
            }
          </style>
          `;
      };

      //segunda pagina
      const body_2 = function (body: pdfBody): string {
        return `
          <div class="row flex-column align-items-center justify-content-center" style="gap: 30px;">
            <img style="width: 16cm;" src="${body["topMotivosConsulta"]}"/>            
            <img style="width: 16cm;" src="${body["distribucionGenero"]}"/>
          </div>

          <div class="footer-2" >
            <div class="container-fluid container-footer-2 text-center d-flex justify-content-center flex-column">
              <p>
                <span style="font-family: 'Nunito', sans-serif; font-weight:300; font-size:12px;"> Generado por: ${body.author} </span> <br><br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:300; font-size:12px;"> Si tienes preguntas sobre alguna métrica, no dudes en contactarnos en <a style="color: white;" href="mailto:atencionalcliente@holadoc.com">atencionalcliente@holadoc.com</a>  Estamos aquí para ayudarte. </span> <br><br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:600; font-size:12px;"> ${body.footer.firstLine} </span> <br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:600; font-size:12px;" > ${body.footer.secondLine} </span>
              </p>
              <div class="d-flex justify-content-around">
                <p style="font-family: 'Nunito', sans-serif; font-weight:700; font-size:12px;"> Tlf: 0212-2346550 </p>
                <p style="font-family: 'Nunito', sans-serif; font-weight:700; font-size:12px;"> RIF J-40959877-2 </p>
              </div>
            </div>
          </div>

          <style>
            .footer-2 {
              color: #FFFFFF;
              width: auto;
              overflow: hidden;
              border: unset;
              margin-top: -84px;
              background-position: center;
              background-repeat: no-repeat; 
              background-size: cover;
              background-image: url(../../../assets/images/footer_pdf.svg); 
            }
            .container-footer-2 {
              padding: 142px 10px 0px 10px;
            }

          </style>
          `;
      };

      //tercera pagina
      const body_3 = function (body: pdfBody): string {
        return `
          <div class="row flex-column align-items-center justify-content-center" style="margin-top: 3cm;">
            <img style="width: 16cm;" src="${body["distribucionEdad"]}"/>
          </div>

          <div class="row justify-content-center flex-row" style="margin-top: 1cm; margin-bottom:1.27cm;">

            <img style="width: 8cm;" src="${body["firstBlock"]}"/>
            <img style="width: 8cm;" src="${body["secondBlock"]}"/>

          </div>

          <div class="footer-3" >
            <div class="container-fluid container-footer-3 text-center d-flex justify-content-center flex-column">
              <p>
                <span style="font-family: 'Nunito', sans-serif; font-weight:300; font-size:12px;"> Generado por: ${body.author} </span> <br><br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:300; font-size:12px;"> Si tienes preguntas sobre alguna métrica, no dudes en contactarnos en <a style="color: white;" href="mailto:atencionalcliente@holadoc.com">atencionalcliente@holadoc.com</a>  Estamos aquí para ayudarte. </span> <br><br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:600; font-size:12px;"> ${body.footer.firstLine} </span> <br>
                <span style="font-family: 'Nunito', sans-serif; font-weight:600; font-size:12px;" > ${body.footer.secondLine} </span>
              </p>
              <div class="d-flex justify-content-around">
                <p style="font-family: 'Nunito', sans-serif; font-weight:700; font-size:12px;"> Tlf: 0212-2346550 </p>
                <p style="font-family: 'Nunito', sans-serif; font-weight:700; font-size:12px;"> RIF J-40959877-2 </p>
              </div>
            </div>
          </div>

          <style>
            .footer-3 {
              color: #FFFFFF;
              width: auto;
              overflow: hidden;
              border: unset;
        
              margin-top: -87px;
              z-index: 100;
              background-position: center;
              background-repeat: no-repeat; 
              background-size: cover;
              background-image: url(../../../assets/images/footer_pdf.svg); 
            }
            .container-footer-3 {
              padding: 142px 10px 0px 10px;
              //margin-bottom: -60px; 
            }

          </style>
          `;
      };

      const page_1 = body_1(body);
      const page_2 = body_2(body);
      const page_3 = body_3(body);

      body["page"] = 1;
      const report_1 = reportDashboardTemplate(body, page_1);
      body["page"] = 2;
      const report_2 = reportDashboardTemplate(body, page_2);
      body["page"] = 3;
      const report_3 = reportDashboardTemplate(body, page_3);

      //primer elemento y pagina
      const tempContainer1 = document.createElement("div");
      // tempContainer1.style.opacity = '0';
      tempContainer1.id = "page1";
      tempContainer1.innerHTML = report_1;
      document.body.appendChild(tempContainer1);

      //segundo elemento y pagina
      const tempContainer2 = document.createElement("div");
      // tempContainer2.style.opacity = '0';
      tempContainer2.id = "page2";
      tempContainer2.innerHTML = report_2;
      document.body.appendChild(tempContainer2);

      //tercer elemento y pagina
      const tempContainer3 = document.createElement("div");
      // tempContainer3.style.opacity = '0';
      tempContainer3.id = "page3";
      tempContainer3.innerHTML = report_3;
      document.body.appendChild(tempContainer3);

      const mainContainer = document.createElement("div");
      // mainContainer.style.opacity = '0';
      mainContainer.id = "mainContainer";
      mainContainer.appendChild(tempContainer1);
      mainContainer.appendChild(tempContainer2);
      mainContainer.appendChild(tempContainer3);
      document.body.appendChild(mainContainer);

      const opt = {
        compression: {
          level: 9,
          strategy: 3,
        },
        margin: 0,
        filename: `reporte-${businessName}_${moment().format(
          "DD-MM-YYYY"
        )}.pdf`,
        image: { type: "svg", quality: 0.98 },
        overflow: "hidden",
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "cm",
          format: "letter",
          orientation: "portrait",
          overflow: "hidden",
          compress: true,
        },
        autoPaging: "text",
      };

      html2pdf()
        .set(opt)
        .from(mainContainer)
        .save()
        .then(() => {
          document.body.removeChild(mainContainer);
          document.body.style.overflow = "auto";
          // this.loadingPDF$.next();
          this.organizationService.showMessage(
            "Descarga finalizada",
            "success"
          );
          this.isLoading = false;
          this.isLoadingReport = false;
        })
        .catch((error) => {
          document.body.removeChild(mainContainer);
          document.body.style.overflow = "auto";
          // console.log(error);
          // this.loadingPDF$.next();
          this.organizationService.showMessage(
            "Error al descargar el archivo",
            "error"
          );
          this.isLoading = false;
          this.isLoadingReport = false;
        });
      // .outputPdf('blob')
      // .then((pdfBlob) => {
      //   this.isLoading = false;
      //   this.isLoadingReport = false;
      //   const pdfUrl = URL.createObjectURL(pdfBlob);
      //   this.isLoadingReport = false;
      //   this.organizationService.showMessage('Descarga finalizada...', 'success');
      //   window.open(pdfUrl);
      //   // Elimina el contenedor temporal
      //   // document.body.removeChild(mainContainer);
      // });
    });
  }
}
