import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { AuthenticationGuard } from "./core/authentication.guard";
import { DashboardComponent } from "./dashboard/dashboard.component";

import { ChangePasswordComponent } from "./home/change-password/change-password.component";

const routes: Routes = [
  {
    path: "members",
    loadChildren: () => import("./home/home.module").then((h) => h.HomeModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [AuthenticationGuard],
  },
  { path: "login", component: LoginComponent },
  { path: "forgot-pwd", component: ForgotPasswordComponent },
  { path: "reset-pwd", component: ResetPasswordComponent },
  { path: "verify", component: ResetPasswordComponent },
  { path: "", pathMatch: "full", redirectTo: "/login" },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
