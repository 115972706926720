import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { CredentialsService } from "./core/credentials.service";
import { catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "./shared/shared.service";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public credentialsService: CredentialsService,
    public translateService: TranslateService,
    private router: Router,
    private sharedService: SharedService,
    private dialog: MatDialog
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.credentialsService.credentials
      ? this.credentialsService.credentials
      : "";
    if (!token) {
      req = req.clone({
        url: environment.base_url + req.url,
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang
        ),
      });
    } else {
      req = req.clone({
        url: environment.base_url + req.url,
        headers: req.headers.set(
          "Accept-Language",
          this.translateService.currentLang
        ),
        setHeaders: {
          // "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          Authorization: `Bearer ${this.credentialsService.credentials.token}`,
        },
      });
    }
    return next.handle(req).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400: {
            if (
              error.error.message.length > 0 &&
              typeof error.error.message[0] === "string"
            ) {
              const msg: string = error.error?.message[0];
              this.sharedService.showErrorMessage(msg);
              return;
            }
            break;
          }
          case 401: {
            this.credentialsService.setCredentials();
            this.dialog.closeAll();
            this.sharedService.dismissMessage();
            this.router.navigate(["/login"]).then(() => {
              const msg: string =
                error.error?.message[0] ||
                "Sesión expirada, haga login nuevamente";
              this.sharedService.showErrorMessage(msg);
            });
            return;
          }
          case 500: {
            const msg: string =
              "Error en el servidor, por favor intente más tarde";
            this.sharedService.showErrorMessage(msg);
            break;
          }
          default: {
            break;
          }
        }
        return throwError(error);
      })
    );
  }
}
